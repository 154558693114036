import { combineReducers } from '@reduxjs/toolkit';

import getUserManagementLisReducer from 'pages/UserManagement/UserManagementList/GetUserManagementList/reducer';
import { getUserGuideReducer } from 'layouts/Header/UserGuide/GetUserGuide/GetUserGuide';

const userManagementReducer = combineReducers({
  list: getUserManagementLisReducer,
  userGuideFiles: getUserGuideReducer,
});

export default userManagementReducer;

// eslint-disable-next-line no-unused-vars
import { IOption } from 'shared/FormikField/FormikField.model';
import { ADD_ORDER_TYPE } from 'pages/WirelineEngagements/WirelineEngagements.models';

const createOrderNotifier = {
  success: 'Order created successfully',
  error: 'Failed to create order',
};

export enum fields {
  tsdId = 'tsdId',
  orders = 'orders',
  carrier = 'carrier',
  params = 'params',
  products = 'products',
  productId = 'productId',
  currentTSD = 'currentTSD',
  categoryId = 'categoryId',
  productName = 'productName',
  locationIds = 'locationIds',
  sellerRequestType = 'sellerRequestType',
  contractSupport = 'contractOrderSupport',
  projectManagementSupport = 'projectManagementSupport',
  customerAssistPm = 'customerAssistPm',
  billingSupport= 'billingSupport',
  customerAssistBilling = 'customerAssistBilling',
}

export const ATAndTLabel = 'AT&T';
export const nonATAndTCategoryLabel = 'Non AT&T';

export enum sellerRequestTypeValues {
  billingSuport = 'BILLING_SUPPORT',
  contractSupport = 'CONTRACT_SUPPORT',
  projectManagerSupport = 'PROJECT_MANAGER_SUPPORT',
}

export const sellerRequestTypeLabels = {
  [sellerRequestTypeValues.billingSuport]: 'Billing Support',
  [sellerRequestTypeValues.contractSupport]: 'Contract/order support',
  [sellerRequestTypeValues.projectManagerSupport]: 'Project Management Support',
};

export const newOrdersSellerRequestTypeATTOptions: IOption[] = [
  {
    label: sellerRequestTypeLabels[sellerRequestTypeValues.contractSupport],
    value: sellerRequestTypeValues.contractSupport,
  },
  {
    label: sellerRequestTypeLabels[sellerRequestTypeValues.projectManagerSupport],
    value: sellerRequestTypeValues.projectManagerSupport,
  },
  {
    label: sellerRequestTypeLabels[sellerRequestTypeValues.billingSuport],
    value: sellerRequestTypeValues.billingSuport,
  },
];

export const newOrdersSellerRequestTypeNonATTOptions: IOption[] = [
  {
    label: sellerRequestTypeLabels[sellerRequestTypeValues.contractSupport],
    value: sellerRequestTypeValues.contractSupport,
  },
];

export const renewelOrderSellerRequestType: IOption[] = [
  {
    label: sellerRequestTypeLabels[sellerRequestTypeValues.contractSupport],
    value: sellerRequestTypeValues.contractSupport,
  },
  {
    label: sellerRequestTypeLabels[sellerRequestTypeValues.billingSuport],
    value: sellerRequestTypeValues.billingSuport,
  },
];

export enum contractSuportValues {
  new = 'NEW',
  reOrder = 'RE_ORDER',
  renewal = 'RENEWAL',
  canceled = 'CANCELED',
}

export const contractOrderSupportLabels = {
  [contractSuportValues.new]: 'New',
  [contractSuportValues.reOrder]: 'Re-order',
  [contractSuportValues.renewal]: 'Renewal',
  [contractSuportValues.canceled]: 'Canceled',
};

export const newOrderContractSupportOptions: IOption[] = [
  {
    label: contractOrderSupportLabels[contractSuportValues.new],
    value: contractSuportValues.new,
  },
  {
    label: contractOrderSupportLabels[contractSuportValues.reOrder],
    value: contractSuportValues.reOrder,
  },
];

export const renewelOrderContractSupportOptions: IOption[] = [
  {
    label: contractOrderSupportLabels[contractSuportValues.renewal],
    value: contractSuportValues.renewal,
  },
];

export enum projectManagerSupportValues {
  orderProcessedByNetSpark = 'ORDER_PROCESSED_BY_NETSPARK',
  customerAssistPM = 'CUSTOMER_ASSIST_OR_PM',
}

export const projectManagementSupportLabels = {
  [projectManagerSupportValues.customerAssistPM]: 'Customer Assist/PM',
  [projectManagerSupportValues.orderProcessedByNetSpark]: 'Order processed by NetSpark',
};

export const projectManagerSupportOptions: IOption[] = [
  {
    label: projectManagementSupportLabels[projectManagerSupportValues.orderProcessedByNetSpark],
    value: projectManagerSupportValues.orderProcessedByNetSpark,
  },
  {
    label: projectManagementSupportLabels[projectManagerSupportValues.customerAssistPM],
    value: projectManagerSupportValues.customerAssistPM,
  },
];

export enum billingSupportValues {
  orderProcessedByNetSpark = 'ORDER_PROCESSED_BY_NETSPARK',
  customerAssistBilling = 'CUSTOMER_ASSIST_OR_BILLING',
}

export const billingSupportLabels = {
  [billingSupportValues.customerAssistBilling]: 'Customer Assist/Billing',
  [billingSupportValues.orderProcessedByNetSpark]: 'Order processed by NetSpark',
};

export const billingSupportOptions: IOption[] = [
  {
    label: billingSupportLabels[billingSupportValues.orderProcessedByNetSpark],
    value: billingSupportValues.orderProcessedByNetSpark,
  },
  {
    label: billingSupportLabels[billingSupportValues.customerAssistBilling],
    value: billingSupportValues.customerAssistBilling,
  },
];

export const addOrdersFieldsPlaceholders = {
  billingSupport: 'Billing Support',
  customerAssistPm: 'Customer Assist/PM',
  contractSupport: 'Contract/order support',
  customerAssistBilling: 'Customer Assist/Billing',
  projectManagementSupport: 'Project Management Support',
};

export const schemaNewOrderSellerRequestType = {
  [sellerRequestTypeValues.contractSupport]: {
    type: 'select',
    fieldName: fields.contractSupport,
    placeholder: addOrdersFieldsPlaceholders.contractSupport,
    options: newOrderContractSupportOptions,
  },
  [sellerRequestTypeValues.projectManagerSupport]: {
    type: 'select',
    fieldName: fields.projectManagementSupport,
    placeholder: addOrdersFieldsPlaceholders.projectManagementSupport,
    options: projectManagerSupportOptions,
    nested: {
      [projectManagerSupportValues.customerAssistPM]: {
        type: 'text',
        fieldName: fields.customerAssistPm,
        placeholder: addOrdersFieldsPlaceholders.customerAssistPm,
      },
    },
  },
  [sellerRequestTypeValues.billingSuport]: {
    type: 'select',
    fieldName: fields.billingSupport,
    placeholder: addOrdersFieldsPlaceholders.billingSupport,
    options: billingSupportOptions,
    nested: {
      [billingSupportValues.customerAssistBilling]: {
        type: 'text',
        fieldName: fields.customerAssistBilling,
        placeholder: addOrdersFieldsPlaceholders.customerAssistBilling,
      },
    },
  },
};

export const schemaRenewelOrderSellerRequestType = {
  [sellerRequestTypeValues.contractSupport]: {
    type: 'select',
    fieldName: fields.contractSupport,
    placeholder: addOrdersFieldsPlaceholders.contractSupport,
    options: renewelOrderContractSupportOptions,
  },
  [sellerRequestTypeValues.billingSuport]: {
    type: 'select',
    fieldName: fields.billingSupport,
    placeholder: addOrdersFieldsPlaceholders.billingSupport,
    options: billingSupportOptions,
    nested: {
      [billingSupportValues.customerAssistBilling]: {
        type: 'text',
        fieldName: fields.customerAssistBilling,
        placeholder: addOrdersFieldsPlaceholders.customerAssistBilling,
      },
    },
  },
};

export const schemaSellerRequestType = {
  [ADD_ORDER_TYPE.NEW_ORDER]: schemaNewOrderSellerRequestType,
  [ADD_ORDER_TYPE.RENEWAL_ORDER]: schemaRenewelOrderSellerRequestType,
};

export interface ISchemaSellerRequestType {
  type: string;
  fieldName: string;
  placeholder: string;
  options: IOption[];
  nested?: ISchemaSellerRequestType;
}

export default createOrderNotifier;

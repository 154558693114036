/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getUserGuideFiles } from './api.service';
// eslint-disable-next-line no-unused-vars
import { IState } from './types';

const initialState: IState = {
  result: [],
  error: null,
  isLoading: false,
};

export const getUserGuideAction = createAsyncThunk(
  'getUserGuideAction',
  async (_, { rejectWithValue }) => {
    try {
      const { data } = await getUserGuideFiles();
      return data;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);

const getUserGuideSlice = createSlice({
  name: 'getUserGuide',
  initialState,
  reducers: {
    clear: () => initialState,
  },
  extraReducers(builder) {
    builder
      .addCase(
        getUserGuideAction.pending,
        (state) => {
          state.isLoading = true;
          state.result = initialState.result;
        },
      )
      .addCase(
        getUserGuideAction.fulfilled,
        (state, action) => {
          state.isLoading = false;
          state.result = action.payload;
        })
      .addCase(
        getUserGuideAction.rejected,
        (state, action) => {
          state.isLoading = false;
          state.error = action.error;
          state.result = initialState.result;
        });
  },
});

export const { clear: clearGetUserGuideData } = getUserGuideSlice.actions;

export const getUserGuideReducer = getUserGuideSlice.reducer;

import React from 'react';
import UserGuideMenuButton from './UserGuideMenuButton';

const UserGuide: React.FC = () => {
  const onSelect = (file: string) => {
    window.open(file, '_blank');
  };

  return (
    <UserGuideMenuButton
      onSelect={onSelect}
    />
  );
};

export default UserGuide;
